<template>
<box-card />


 <el-row :gutter="10">
    <el-col :span="8"><div id="main" class="chartsstyle" /></el-col>
    <el-col :span="8"><div id="main1" class="chartsstyle" /></el-col>
    <el-col :span="8"><div id="main2" class="chartsstyle" /></el-col>
  </el-row>


<el-row>
    <el-col :span="12">
     <el-table :data="tableData" style="width: 90%" size="small">
    <el-table-column prop="name" label="实时政务" width="auto" />
    <el-table-column prop="date" label="时间" width="150"/>
  </el-table>
</el-col>
<el-col :span="12">
    <el-table :data="tableData1" style="width: 90%" size="small">
    <el-table-column prop="name" label="跃龙集团动态" width="auto" />
    <el-table-column prop="date" label="时间" width="150"/>
  </el-table>

</el-col>


</el-row>


</template>

<script setup>
import BoxCard from './components/BoxCard'
import { onMounted, onBeforeUnmount } from 'vue'
import * as echarts from 'echarts';

const tableData = [
  {
    date: '2022-07-16',
    name: '习近平：建设团结和谐繁荣富裕文明进步安居乐业生态良好的美好新疆',
  },
  {
    date: '2022-07-14',
    name: '李克强主持召开国务院常务会议 部署加力稳岗拓岗的政策举措 确保就业大局稳定',
  },
  {
    date: '2022-07-11',
    name: '全国范围取消“国五”二手车迁入限制，推进在居住社区加装充电设施……最新发布！',
  },
  {
    date: '2022-07-11',
    name: '7月上旬局部地区疫情有所反弹、多项措施防范奥密克戎变异株BA.5和BA.4......最新情况通报！',
  },
]
const tableData1 = [
    {
        date:'2022-07-14',
        name:'跃龙集团一周工作概览'
    },
    {
        date:'2022-07-09',
        name:'2022年明光跃龙投资控股集团有限公司招聘人员笔试成绩公示'
    },
    {
        date:'2022-01-01',
        name:'跃龙集团2022年新年献词'
    }
]
let myChart = null
let myChart1 = null
let myChart2 = null
onMounted(() => {
    // 基于准备好的dom，初始化echarts实例
 myChart = echarts.init(document.getElementById('main'));
 myChart1 = echarts.init(document.getElementById('main1'));
 myChart2 = echarts.init(document.getElementById('main2'));
// 绘制图表
myChart.setOption({
  title: {
    text: '近期考核分数趋势'
  },
  xAxis: {
    type: 'category',
    data: ['05/10', '05/20', '05/30', '06/10', '06/20', '06/30', '07/10']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [70, 90, 96.2, 98.5, 100, 101, 105],
      type: 'line',
     
    }
  ]
});
myChart1.setOption(option)
myChart2.setOption(option2)


})
onBeforeUnmount(() => {
  if (!myChart) {
    return
  }
  myChart.dispose()
  myChart1.dispose()
  myChart2.dispose()
  myChart = null
  myChart1 = null
  myChart2 = null
})



//考评分数
let option = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '5%',
    left: 'center'
  },
  series: [
    {
      name: '考核结果',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '40',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 32, name: '非常优秀' },
        { value: 566, name: '优秀' },
        { value: 113, name: '一般' },
        { value: 12, name: '很差' },

      ]
    }
  ]
};
let option2 = {
  title: {
    text: '服务单位类型占比',
   // subtext: 'Fake Data',
    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 126, name: '事业单位' },
        { value: 21, name: '企业' },
        { value: 56, name: '党政机关' },
        { value: 10, name: '其他' },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

</script>

<style scoped>
.chartsstyle{
    width: 500px;
    height: 400px;
}
</style>
