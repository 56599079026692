<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :span="4" :xs="12" :sm="12" :lg="6" class="card-panel-col card-panel">
      <el-card shadow="hover">
        <div class="card-panel-icon-wrapper icon-people">
          <svg-icon icon-class="people" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">所有员工</div>
          <span class="card-panel-num">{{serverInfo.all_people}}人</span>
          <!--<count-to :start-val="0" :end-val="102400" :duration="2600" class="card-panel-num" />-->
        </div>
      </el-card>
    </el-col>
    <el-col :span="4" :xs="12" :sm="12" :lg="6" class="card-panel-col card-panel">
      <el-card shadow="hover">
        <div class="card-panel-icon-wrapper icon-onpeople">
          <svg-icon icon-class="onpeople" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">在职员工</div>
          <span class="card-panel-num">{{serverInfo.online_people}}人</span>
          <!--<count-to :start-val="0" :end-val="102400" :duration="2600" class="card-panel-num" />-->
        </div>
      </el-card>
    </el-col>
    <el-col :span="4" :xs="12" :sm="12" :lg="6" class="card-panel-col card-panel">
      <el-card shadow="hover">
        <div class="card-panel-icon-wrapper icon-people">
          <svg-icon icon-class="dw" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">服务单位</div>
          <span class="card-panel-num">{{serverInfo.services}}家</span>
  
        </div>
      </el-card>
    </el-col>
    <el-col :span="4" :xs="12" :sm="12" :lg="6" class="card-panel-col card-panel">
      <el-card shadow="hover">
        <div class="card-panel-icon-wrapper icon-pf">
          <svg-icon icon-class="pf" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">平均分数</div>
          <span class="card-panel-num">93</span>
          <!--<count-to :start-val="0" :end-val="102400" :duration="2600" class="card-panel-num" />-->
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script setup>
import {getAllPeopleReq} from '@/api/index.js'
import { onBeforeMount ,reactive} from 'vue'

const serverInfo = reactive({
  all_people:0,
  online_people:0,
  services:0,
})

onBeforeMount(()=>{
  getAllPeopleReq().then(res=>{
     serverInfo.all_people = res.all_people
     serverInfo.online_people = res.online_people
     serverInfo.services = res.services
  })
})


</script>


<style lang="scss" scoped>
.card-panel-col {
  margin-bottom: 32px;
}
.panel-group {
  margin-top: 18px;
}
.card-panel-num {
  font-size: 20px;
}
.card-panel-icon {
  float: left;
  font-size: 48px;
}
.card-panel-description {
  float: right;
  font-weight: bold;
  margin: 26px;
  margin-left: 0px;
}
.card-panel-text {
  line-height: 18px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  margin-bottom: 12px;
}
.card-panel-icon-wrapper {
  float: left;
  margin: 14px 0 0 14px;
  padding: 16px;
  transition: all 0.38s ease-out;
  border-radius: 6px;
}


.icon-people {
      color: #40c9c6;
    }
.icon-onpeople {
      color: #1296db;
    }
  .icon-pf{
    color:#f4516c;
  }
    .card-panel {
   
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    border-color: rgba(0, 0, 0, 0.05);
    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }
      .icon-people {
        background: #40c9c6;
      }
      .icon-onpeople {
        background: #36a3f7;
      }
      .icon-pf {
        background: #f4516c;
      }
      .icon-shopping {
        background: #34bfa3;
      }
    }
      }

</style>
